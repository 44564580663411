export default function() {
    let isScrolling = false;
    $(document).ready(function() {

        const slider = $(".project-slider");
        slider.slick({
            dots: true,
            infinite: false,
            variableWidth: true,
            //autoplay: true,
            autoplaySpeed: 5000
        });

        slider.on('afterChange', function(slick, currentSlide){
            setTimeout(function(){
                isScrolling = false;
            }, 600);

        });
        slider.on('wheel', (function(e) {

            if (isScrolling  || $(window).width() < 768) {
                return true;
            }

            e.preventDefault();
            isScrolling = true;

            if (e.originalEvent.deltaX > 0) {
                $(this).slick('slickNext');
            } else {
                $(this).slick('slickPrev');
            }

        }));

    });


}