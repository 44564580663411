export default function () {
    let loadedSection = window.location.hash;
    window.location.hash = "";
    var fullPageCreated = false;


    function createFullpage() {
        if (fullPageCreated === false) {
            fullPageCreated = true;

            $('#fullpage').fullpage({
                licenseKey: 'OPEN-SOURCE-GPLV3-LICENSE',
                // options here
                scrollingSpeed: 1200,
                //easing: 'easeOut',
                easingcss3: 'cubic-bezier(0.76, 0, 0.24, 1)',
                fitToSection: true,
                //fitToSectionDelay: 5000,
                //scrollBar: true,
                parallax: true,
                responsiveWidth: 992,
                responsiveHeight: 880,
                keyboardScrolling: false,
                onLeave: function (origin, destination, direction) {
                    var leavingSection = this;
                    //after leaving section 2
                    // SCREEN HERO TO OUR SERVICES
                    //console.log($(destination));
                    var destinationIndex = $('section').eq(destination.index);
                    var currentIndex = $('section').eq(origin.index);
                    activeScrollMenu(destinationIndex);
                    if (direction == 'down') {
                        destinationIndex.addClass('animation-start');
                        //$('#header').removeClass('nav-up').addClass('nav-down');
                       $('#header').addClass('nav-up');
                        if (origin.index == 0) {
                            $('.hero-logo__mb').addClass('active');
                            $(".scroll-ico").fadeOut(600);
                          //  $('#header').removeClass('nav-down').removeClass('nav-up');
                            $('#header').removeClass('nav-up');
                        }
                    } else if (direction == 'up') {
                        currentIndex.removeClass('animation-start');
                        destinationIndex.removeClass('animation-start');
                        //$('#header').removeClass('nav-down').addClass('nav-up');
                        $('#header').addClass('nav-up');
                        if (destination.index == 0) {
                            $('.hero-logo__mb').removeClass('active');
                            $(".scroll-ico").fadeIn(800);
                           // $('#header').removeClass('nav-down').removeClass('nav-up');
                            $('#header').removeClass('nav-up');
                        }
                    }
                },
                afterRender: function () {
                    setTimeout(loadSection, 100);
                },
            });

        }
    }


    $(window).resize(function () {
        //moblie width change 400
        if ($(window).width() > 992) {
            createFullpage();
        } else {
            if (fullPageCreated == true) {
                fullPageCreated = false;
                $.fn.fullpage.destroy('all');
            }
        }

    });

    $(document).ready(function () {
        setTimeout(function () {
            createFullpage();
        }, 200);
        //();

        AOS.init({
            once: true,
            duration: 800
        });

        if (document.documentElement.clientWidth < 992) {
            //  $.fn.fullpage.destroy('all');
        }

        $('.navbar .nav-item .nav-link').click(function (e) {
            let url = $(this).attr('href');
            if (isFrontPage() && url.indexOf("#") != -1) {
                activeMenu($(this));
                if (url.indexOf("#") != -1) {
                    let hash = url.split('#')[1];

                    moveToSection(hash, false);

                    setTimeout(function () {
                        window.location.hash = hash;
                    }, $(window).width() < 992 ? 800 : 1200);
                } else {
                    window.location.href = url;
                }
                e.preventDefault();
                return false;
            }
        });

    });

    function loadSection() {
        if (isFrontPage()) {
            activeLoadedMenu();
            let hash = loadedSection.replace('#', '');
            if (hash != '') {
                // window.location.hash = hash;
                moveToSection(hash, true);
                setTimeout(function () {
                    window.location.hash = hash;
                }, $(window).width() < 992 ? 600 : 1200);

            }

        }
    }

    function isFrontPage() {
        let path = window.location.pathname;
        let pattern = /^\/(en|da|de)?\/?$/i;
        return pattern.test(path); // == "/" || path == "/en/"|| path == "/en" || path == "/da/" || path == "/de/";
    }

    function activeMenu($ele) {
        $('.nav-item').removeClass('active');
        $ele.closest('.nav-item').addClass('active');
    }

    function moveToSection(hash, isVisible) {
        let $section = $('#' + hash);
        if ($section.length > 0) {
            let secIdx = $section.closest('section').data('index');
            if ($(window).width() < 992) {
                gotoSectionMobile($section.closest('section'));
            } else {
                $.fn.fullpage.moveTo(secIdx);
            }
            closeMenuMobile();
        }
    }

    function gotoSectionMobile($ele) {
        $('html,body').animate({
            scrollTop: ($ele.offset().top - ($('header').height() + 30))
        }, 'slow');
    }

    function closeMenuMobile() {
        if ($(window).width() < 992) {
            $('.navbar-toggler').removeClass("active");
            $('#side-navbar').removeClass("active");
        }
    }

    function activeLoadedMenu(){
        if(loadedSection != ""){
            $('.nav-item').removeClass('active');
            $('.nav-item a[href*="' + loadedSection+'" i]').closest('.nav-item').addClass('active');
        }
    }

    function activeScrollMenu($ele){
        var id= $('.section-wr',$ele).attr('id');
        $('.nav-item').removeClass('active');
        $('.nav-item a[href*="#' + id+'" i]').closest('.nav-item').addClass('active');
    }



}


