import '../styles/thirdspace.scss';
import header from "./components/header";
import slider from "./components/slider";
import pageScroll from "./components/page-scroll";
import videos from './components/videos';

$(() => {
    header();
    slider();
    pageScroll();
    videos();
});