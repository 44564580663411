export default function() {
    var didScroll;
    var lastScrollTop = 0;
    var delta = 5;
    var navbarHeight = $('#header').outerHeight();
    $(document).ready(function () {

        setInterval(function() {
            if (didScroll) {
                hasScrolled();
                didScroll = false;
            }
        }, 200);

        function hasScrolled() {
            var st = $(window).scrollTop();

            var ratio = 1 - (st / 100);

            $(".scroll-ico").css('opacity', `${ratio}`);

            /*$(".standard-page .float-img-container img").waypoint(function () {
                $(this[0, 'element']).each(function () {

                    $(this).css('transform', `translate3d(0,${ratio}px,0)`);
                });
            }, {offset: '75%'});*/

            // Make sure they scroll more than delta
            if (Math.abs(lastScrollTop - st) <= delta)
                return;

            // If they scrolled down and are past the navbar, add class .nav-up.
            // This is necessary so you never see what is "behind" the navbar.
            if (st > lastScrollTop && st > 20) {
                // Scroll Down
                $('#header').removeClass('nav-up').addClass('nav-down');
            }
            else if (st < lastScrollTop && st > 20){
                // Scroll Up
                //  if(st + $(window).height() < $(document).height()) {
                $('#header').removeClass('nav-down').addClass('nav-up');
                //  }
            }

            if(st < 20){
                $('#header').removeClass('nav-down').removeClass('nav-up');
            }

            lastScrollTop = st;
        }


    });

    $(window).scroll(function() {
        didScroll = true;
      /*  var winScroll = $(this).scrollTop();
        var height = $(document).outerHeight() - $(window).outerHeight();
        var scrolled = (winScroll / height) * 100;
        if ($('#pr-bar').length > 0) {
            $("#pr-bar").css('width', scrolled + "%");
        }*/
    });
}